<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('employees.staff_management') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <DatatableDropdown />
              <!--end::Dropdown-->
              <!--begin::Button-->
              <router-link :to="{ name: 'EmployeesNew' }" class="btn btn-primary font-weight-bolder">
                <span class="svg-icon svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" cx="9" cy="15" r="6" />
                      <path
                        d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon--> </span
                >{{ $t('employees.add_personnel') }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class='card-body'>
            <form class="mb-15">
              <div class="row mb-6">
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <label>ID:</label>
                  <input type="text" class="form-control datatable-input" placeholder="ID" data-col-index="0">
                </div>
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <label>{{ $t('commons.fullname') }}:</label>
                  <input type="text" class="form-control datatable-input" :placeholder="$t('commons.fullname')" data-col-index="1">
                </div>
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <label>{{ $t('commons.department') }}:</label>
                  <select class="form-control datatable-input" data-col-index="2">
                    <option value="">{{ $t('commons.select') }}</option>
                  </select>
                </div>
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <label>{{ $t('commons.state') }}:</label>
                  <select class="form-control datatable-input" data-col-index="4">
                    <option value="">{{ $t('commons.select') }}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-8">
                <div class="col-lg-12">
                <button class="btn btn-primary btn-primary--icon" id="kt_search">
                  <span>
                    <i class="la la-search"></i>
                    <span>{{ $t('commons.search') }}</span>
                  </span>
                </button>&nbsp;&nbsp;
                <button class="btn btn-secondary btn-secondary--icon" id="kt_reset">
                  <span>
                    <i class="la la-close"></i>
                    <span>{{ $t('commons.reset') }}</span>
                  </span>
                </button></div>
              </div>
            </form>
            <div v-if="isLoading">
              <table class="table table-striped table-bordered collapsed" style="width:100%">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t('commons.fullname') }}</th>
                  <th scope="col">{{ $t('commons.department') }}</th>
                  <th scope="col">{{ $t('commons.role') }}</th>
                  <th scope="col">{{ $t('commons.state') }}</th>
                  <th scope="col">{{ $t('commons.date') }}</th>
                  <th scope="col">{{ $t('commons.transactions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in 10" :key="'loading-' + index">
                  <th scope="row"><Skeleton height="38px" /></th>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                </tr>
                </tbody>
              </table>
            </div>
            <table class="table table-striped table-bordered collapsed" style="width:100%" v-else ref="kt_datatable">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t('commons.fullname') }}</th>
                <th scope="col">{{ $t('commons.department') }}</th>
                <th scope="col">{{ $t('commons.role') }}</th>
                <th scope="col">{{ $t('commons.state') }}</th>
                <th scope="col">{{ $t('commons.date') }}</th>
                <th scope="col">{{ $t('commons.transactions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in datas" :key="'data-' + index">
                <th scope="row">{{ item.id }}</th>
                <td v-html="item.name"></td>
                <td>{{ (item.department) ? $t(`service.${item.department}`) : item.department }}</td>
                <td>{{ item.departmentName }}</td>
                <td>{{ (item.status) ? $t(`service.${item.status}`) : item.status }}</td>
                <td v-html="item.created_at"></td>
                <td>
                    <router-link v-if="checkPermission('crm.employees.show')" :to="`/employees/details/${item.id}`" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.show')">
                      <i class="la la-eye"></i>
                    </router-link>
                    <a v-if="checkPermission('crm.employees.show')" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('employees.past_transactions')" @click="showEmployeeAudit(item.id)">
                      <i class="la la-history"></i>
                    </a>
                    <router-link v-if="checkPermission('crm.employees.edit')" :to="`/employees/update/${item.id}`" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.edit')">
                        <i class="la la-pencil"></i>
                    </router-link>
                    <button v-if="checkPermission('crm.employees.update')" type="button" @click="employees2Fa(item.id)" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('employees.reset_authenticator')">
                        <i class="la la-google"></i>
                    </button>
                    <button v-if="checkPermission('crm.employees.destroy')" type="button" @click="deleteEmployees(item.id)" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.delete')">
                        <i class="la la-trash"></i>
                    </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
    <AuditModal v-if="showAuditModal" ref="modalName" :close="() => $store.commit('employees/TOGGLE_AUDIT_MODAL', false)">
      <template v-slot:header>
        <h1>{{ $t('employees.audit_modal_title') }}</h1>
      </template>
      <template v-slot:body>
        <table class="table table-bordered collapsed table-head-bg" style="width:100%">
          <thead>
          <tr>
            <th scope="col">{{ $t('employees.audit_user') }}</th>
            <th scope="col">{{ $t('employees.audit_area') }}</th>
            <th scope="col">{{ $t('employees.audit_old_value') }}</th>
            <th scope="col">{{ $t('employees.audit_new_value') }}</th>
            <th scope="col">{{ $t('employees.audit_date') }}</th>
          </tr>
          </thead>
          <tbody v-for="(auditItem, index) in employees_audit" :key="'audit-' + index">
            <tr v-for="(newValKey, newValKeyIndex) in Object.keys(auditItem.new_values)" :key="'newValKeyIndex-' + newValKeyIndex">
              <td scope="row"><span class="truncateText">
                <template v-if="auditItem.user">
                  <a href="javascript:;" @click="goToUserDetails(auditItem.user.id)">
                    {{ auditItem.user['name'] }} {{ auditItem.user['surname'] }}
                  </a>
                </template>
                </span>
              </td>
              <td scope="row"><span class="truncateText">{{ newValKey }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.old_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.new_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.updated_at }}</span></td>
            </tr>
          </tbody>
        </table>
      </template>
    </AuditModal>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import $ from "jquery";
import AuditModal from '@/components/custom/AuditModal.vue';
import { Skeleton } from 'vue-loading-skeleton';
import Swal from 'sweetalert2'
import DatatableDropdown from '@/components/datatable/Dropdown.vue'

export default {
  name: 'TransactionsList',
  data() {
    return {
      isLoading:true,
    }
  },
  components: {
    Breadcrumb,
    AuditModal,
    Skeleton,
    DatatableDropdown
  },
  methods: {
    ...mapActions('employees', ['DELETE_EMPLOYESS', 'TWO_FACTOR_EMPLOYESS', 'GET_EMPLOYESS_AUDIT']),
    deleteEmployees(id) {
      const vm = this;
      Swal.fire({
        title: this.$t('commons.are_you_sure'),
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes_delete_it'),
        cancelButtonText: this.$t('commons.cancel'),
      }).then(function (result) {
        if (result.value) {
          vm.DELETE_EMPLOYESS(id).then(() => {
            vm.$store.dispatch('employees/GET_EMPLOYESS')
          })
        }
      });
    },
    employees2Fa(id) {
      console.log("id: ",id)
      this.TWO_FACTOR_EMPLOYESS(id);
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
    showEmployeeAudit(id) {
      console.log("id: ",id);
      this.GET_EMPLOYESS_AUDIT(id);
    },
    goToUserDetails(user_id) {
      this.$store.commit('employees/TOGGLE_AUDIT_MODAL', false);
      this.$router.push({ path: `/users/${user_id}` })
    },
    initDatatable() {
      const self = this;
      self.$store.dispatch('employees/GET_EMPLOYESS').then(function () {
        self.isLoading = false;
        self.$nextTick(function (){
          var fileName = 'Employees';
          var table = $(self.$refs['kt_datatable']).DataTable({
            responsive: true,
            order: [[ 0, 'desc' ]],
            pagingType: 'full_numbers',
            dom: 'Blfrtip',
            // read more: https://datatables.net/examples/basic_init/dom.html
            buttons: ['copy', 'csv', 'excel', 'pdf', 'print'].map(el => (
              {
                extend: el,
                filename: fileName
              }
            )),
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            pageLength: 10,
            initComplete: function() {
              this.api().columns().every(function() {
                var column = this;
                switch (column[0][0]) {
                  case 2:
                    column.data().unique().sort().each(function(d) {
                      $('.datatable-input[data-col-index="2"]').append('<option value="' + d + '">' + d + '</option>');
                    });
                    break;

                  case 4:
                    column.data().unique().sort().each(function(d) {
                      $('.datatable-input[data-col-index="4"]').append('<option value="' + d + '">' + d + '</option>');
                    });
                    break;
                }
              });
              $('.dt-buttons').hide();
            },
            columnDefs: [
              { orderable: false, targets: -1 },
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 }
            ],
            language: {
                url: `/assets/languages/datatable/${this.lang}.json`
            }
          });


          $("#printExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-print").trigger("click");
          });

          $("#copyExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-copy").trigger("click");
          });

          $("#excelExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-excel").trigger("click");
          });

          $("#csvExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-csv").trigger("click");
          });

          $("#pdfExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-pdf").trigger("click");
          });

          $('#kt_search').on('click', function(e) {
            e.preventDefault();
            var params = {};
            $('.datatable-input').each(function() {
              var i = $(this).data('col-index');
              if (params[i]) {
                params[i] += '|' + $(this).val();
              }
              else {
                params[i] = $(this).val();
              }
            });
            $.each(params, function(i, val) {
              // apply search params to datatable
              table.column(i).search(val ? val : '', false, false);
            });
            table.table().draw();
          });

          $('#kt_reset').on('click', function(e) {
            e.preventDefault();
            $('.datatable-input').each(function() {
              $(this).val('');
              table.column($(this).data('col-index')).search('', false, false);
            });
            table.table().draw();
          });
        })
      })
    }
  },
  computed: {
    ...mapState({
      datas: state => state.employees.employees,
      employees_audit: state => state.employees.employees_audit,
      showAuditModal: state => state.employees.showAuditModal,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('general_management.title'),
        subtitle: [
          this.$t('employees.title')
        ]
      }
    }
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.employees.index')) {
      this.$router.push('/no-permission')
    }
    this.initDatatable();
  },
}
</script>
<style lang="scss" scoped>
  .truncateText {
    min-width: 150px;
    overflow: hidden;
    position: relative;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
</style>
